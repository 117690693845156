<template>
  <div class="allmsg">
    <pre>
<h1>背景调查服务协议</h1>

<h2>甲方：</h2>
<h2>乙方：上海猎后征信服务有限公司</h2>
    根据《中华人民共和国合同法》及有关法律法规的规定，经双方友好协商， 就甲方委托乙方提供员工/应聘候选人（以下简称“被调查人”）背景调查服务事宜达成协议如下：
<h3>一、服务内容及方式</h3>
    1．甲方委托乙方完成“员工背景调查”服务项目。
    2．乙方根据甲方要求以及被调查人的同意和授权，在合法的信息采集渠道内，提供客观的背景调查报告。
    3．乙方完成背景调查报告后，通过平台向甲方提交《背景调查报告》。
    4．本合同自甲方点击确认后即时生效，自生效之日起，有效期至该合同对应订单完成交付。

<h3>二、付款方式及发票</h3>
    1．甲方应根据对应订单价格，通过平台的在线支付渠道将订单款项支付给乙方。
    2．乙方根据甲方要求于收款后提供增值税普通发票，乙方可以通过平台填写开票信息与寄送地址。

<h3>三、权利和义务</h3>
    1．<strong>甲方承诺并保证在向乙方申请服务前，已获得被调查人对其使用背景调查服务的许可，同时允许或配合乙方获得被调查人的充分授权及完整信息表。</strong>甲方充分理解并同意，为实现本协议目的而对乙方的委托授权是必要的、充分的、不可撤销且合法有效的授权。若因被调查人未做合法有效授权的，乙方有权拒绝提供服务；若因被调查人信息资料不完整或不配合补充必要信息的，甲方理解并同意乙方不能完成相关调查内容或迟延交付调查报告。
    2．甲方应限于自身主体范围内使用乙方服务和产品，不得将乙方提供的技术和服务用于履行本协议目的以外的其他任何用途或从事任何非法活动。甲方应确保对乙方技术及服务的使用符合法律法规的规定，不侵犯任何第三方的合法权益。
    3．乙方保证提供给甲方的背景调查报告中涉及的客观信息均经过尽责核实，并保证真实记录调查结果。若甲方发现乙方核实并记录在报告中的客观信息有误，甲方有权要求乙方对该调查事项重新出具调查报告，并无需对该订单支付费用。若因乙方故意或者重大过失导致记录在报告中的客观信息与渠道不一致或报告中存在虚假、隐瞒等不客观内容的，乙方应全额退回甲方就该笔订单已支付费用。
    4．乙方提供本协议项下的服务不构成对甲方在内的任何人之明示或暗示的观点，乙方不对甲方是否雇佣被调查人提出任何建议。乙方在提交客观报告内容的前提下，不对甲方使用该等结果作出的决策以及因该等决策导致的后果负责。

<h3>四、信息安全及保密条款</h3>
    1．任何一方在合同履行过程中所获得或接触到的另一方或被调查人的个人信息、商业秘密等需要保密的信息，除本合同约定之调查目的外，未经另一方及其他权利人书面同意，不得直接或间接的以任何方式提供或披露给任何“第三方”。本条中“第三方”是指除甲乙双方及被调查人之外的任何自然人、企业或其分支机构、代理、组织或其他民事主体。
    2．甲方应严格做好甲方用户的权限管理，以防止账号或被调查人身份信息泄露等。
    3．为维护和保障用户个人信息安全，甲方理解并同意乙方采取的各项加密及技术措施：乙方获取用户授权后，将对个人信息采用适当的加密方式传输或处理。
    4．乙方在使用甲方提供的信息过程中，若发生信息泄露等安全事故时乙方有义务立即通知甲方，并采取防止进一步扩大影响的紧急措施。

<h3>五、免责条款</h3>
    1．因国家法律法规修订、政策调整、自然灾害、能源供应、数据来源变更(包括但不限于其服务内容及形式的变更)等不可抗力，导致本合同约定的调查内容全部或部分无法履行的，双方需友好协商变更合同内容或另行签订补充协议，因此造成的损失由各方自行承担。
    2．因不可抗力导致调查无法或暂时无法进行的，合同履行期限相应顺延，延长期限为不可抗力的存续期间。任何一方均有义务在上述影响因素发生后立即通知对方，并于上述影响因素发生后15天内将有关机构或组织出具的证明文件用快递方式寄给对方确认。
    3．为改善服务质量，乙方有权对系统进行调整、升级、扩容等措施，涉及影响甲方服务使用的，乙方需提前2个工作日通知甲方，因此导致服务中断的情形乙方不承担责任。
    4．在任何情况下，双方都不对任何间接损害（包括商誉损失、利润损失、收入损失、营业中断、缔约机会损失、预期利益损失，及其他间接的、随附性的、惩罚性的损失）承担赔偿责任。除知识产权、商业秘密侵权及授权违约外，一方承担的违约或赔偿金额累计不应超过双方已确认的协议总额。

<h3>六、争议解决</h3>
    本合同履行过程中如发生争议，应友好协商解决，协商不成，双方同意向上海仲裁委员会提起仲裁。

    </pre>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.allmsg {
  background-color: #ffffff;
  overflow: hidden;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  padding: 0.2rem;
}
</style>